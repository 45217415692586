function FlightQuantitySelector(travelers) {
    var page = dataActive.location.route.uri;
    travelers = travelers || { adults: 1, children: 0, infants: 0 };
    var $input = $(".flight-quantity-selector-input");
    $input.after(view("flight/flight-quantity-selector-box"));
    var $quantitySelector = $input.parent();
    var $box = $quantitySelector.find(".quantity-selector-box");
    var quantitySection = $input.prev();
   
    if(!page.includes('result')) {
        $(quantitySection).on('click', () => $quantitySelector.addClass('open'));
    }
    else {
        $input.click(function () {
            $box.show();
        });
    }

    $('html').click(function (event) {
        var $element = $(event.target);
        if ($quantitySelector.has($element).length == 0 && $element.closest('.close').length == 0) {
            $box.hide();
            $quantitySelector.removeClass('open')
        }
    });
    $quantitySelector.find('.flight-qs-done-btn>button').click(function (event) {
        $(event.target).closest('.quantity-selector-box').hide();
        $quantitySelector.removeClass('open');
    });

    var $travelSection = $(view("flight/traveler-section", {
        adults: travelers.adults || 1,
        children: travelers.children || 0,
        infants: travelers.infants || 0,
    }));

    $quantitySelector.find('.flight-qs-done-btn').before($travelSection);

    if (window.matchMedia('(max-width: 767px)').matches) {
        $quantitySelector.find('.qs-title').prepend('<button type="button" class="csm-close">&#215;</button>');
    }
    $quantitySelector.find('.csm-close').click(function () {
        $box.hide();
    });

    $box.find(".quantity-selector-increment").click(quantityIncrement);
    $box.find(".quantity-selector-decrement").click(quantityDecrement);

    function quantityIncrement() {
        var $this = $(this);
        var $current = $this.prev('.quantity-selector-current');
        var type = $this.closest('.selector-type').find('p').text();
        var currentNumber = parseInt($current.text());
        $(this).prev().prev().removeClass('disabled');

        ///> Number of adults less then 9 in each search.
        if (type == trans('Adults') && currentNumber >= 9) {
            return;
        }
        else if(type == trans('Adults') && currentNumber >= 8) {
            $(this).addClass('disabled')
        }
        ///> Number of childern less then 6 in each serach.
        if (type == trans('Children') && currentNumber >= 6) {
            return;
        }
        else if(type == trans('Children') && currentNumber >= 5) {
            $(this).addClass('disabled')
        }
        ///> Age less then 6 is children.
        if (type == trans('Infants') && currentNumber > 6) {
            return;
        }
        else if(type == trans('Infants') && currentNumber >= 5) {
            $(this).addClass('disabled')
        }

        currentNumber++;
        $current.text(currentNumber);
        var $selectorInput = $this.closest('.selector-type').find('input');
        $selectorInput.val(currentNumber);

        calculate();
    }

    function quantityDecrement() {
        var $this = $(this);
        var $current = $this.next('.quantity-selector-current');
        var type = $this.closest('.selector-type').find('p').text();
        var currentNumber = parseInt($current.text());
        $(this).next().next().removeClass('disabled');
        if (type == trans('Adults') && currentNumber < 2) {
            return;
        }
        else if(type == trans('Adults') && currentNumber < 3) {
            $(this).addClass('disabled')
        }

        if (currentNumber == 0) {
            return;
        }
        else if(currentNumber == 1)
            $(this).addClass('disabled')
        currentNumber--;
        $current.text(currentNumber);
        var $selectorInput = $this.closest('.selector-type').find('input');
        $selectorInput.val(currentNumber);

        calculate();
    }

    function calculate() {

        var adults = 0;
        $box.find('.selector-type-adult').each(function (index, item) {
            adults += parseInt($(item).text());
        });
        children = 0;
        $box.find('.selector-type-child').each(function (index, item) {
            children += parseInt($(item).text());

        });
        infants = 0;
        $box.find('.selector-type-infant').each(function (index, item) {
            infants += parseInt($(item).text());
        });
        if(!page.includes('result')) {
            let totalPassengers = adults + children + infants;
            $(quantitySection).find('span').text(`${totalPassengers} ${trans('Travelers')}`);
        }
        else {
            $input.val(adults + trans('Adult') + " . " + children + trans('Child') + " . " + infants + trans('Infants'));
        }
    }

    calculate();

}