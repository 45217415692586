function CustomDatepicker(selector, config) {
    var $element = $(selector);
    switch ($element.length) {
        case 0:
            return;
        case 1:
            break;
        default:
            $element.each((index, item) => {
                new CustomDatepicker(item, config);
            });
            return;
    }
    config = config || {};
    var calendar = config.calendarType;
    var wrapDate = new WrapDate(calendar);
    this.year = wrapDate.getYear();
    this.month = wrapDate.getMonth();
    this.months = wrapDate.rangeNameMonths();
    this.weekdays = wrapDate.rangeNameWeekdays();
    var me = this;
    var nextDay = wrapDate.getNextDate('24');
    var threeDayLater = wrapDate.getNextDate('72');
    var customDatepickerWrapper = $(`<div class="custom-datepicker-wrapper"><div class="custom-datepicker-header"><button class="_desk-h close-btn" type="button">&#215;</button><button class="prev datepicker-control _mob-h" type="button"><i class="fa fa-angle-left"></i></button><button class="next datepicker-control _mob-h" type="button"><i class="fa fa-angle-right"></i></button></div><div class="custom-datepicker-months"></div></div>`);
    var datepickerTable = view('partials/datepicker-table', { weekdays: me.weekdays });
    var datePickerContainer = $element.parent();
    $element.after(customDatepickerWrapper);
    var page = dataActive.location.route.uri;

    if (config.dateRange == true) {
        if ($element.hasClass('start-date') && $element.val() == '') {
            $element.val(nextDay);
            $element.next().next().val(nextDay);
        }
        if ($element.hasClass('end-date') && $element.val() == '') {
            $element.val(threeDayLater);
            $element.next().next().val(threeDayLater);
        }
    }
    $element.prev().click(function () {
        (config.beforeShow || (() => { }))();
        me.draw();
        customDatepickerWrapper.show();
    });

    $element.click(function () {
        (config.beforeShow || (() => { }))();
        me.draw();
        customDatepickerWrapper.show();
    });

    $('html').click(function (event) {
        if (datePickerContainer.has(event.target).length == 0) {
            customDatepickerWrapper.hide();
        };
    });

    customDatepickerWrapper.find('.close-btn').click(function () {
        (config.afterShow || (() => { }))();
        customDatepickerWrapper.hide();
    });

    var nextBtn = datePickerContainer.find('.next');
    var prevBtn = datePickerContainer.find('.prev');

    this.selectDate = function () {
        var currentDate = wrapDate.getCurrentDate();
        var datePickerDays = datePickerContainer.find('.custom-datepicker-day');
        datePickerDays.each((index, item) => {
            var $item = $(item);
            if ($element.val() == '') {
                if ($item.data('value') == currentDate) {
                    $(item).addClass('selectedDate');
                }
                $element.val(currentDate);
                $element.next().next().val(currentDate);
            } else {
                if ($item.data('value') == $element.val()) {
                    $(item).addClass('selectedDate');
                }
            }
            if ($item.data('value') < currentDate) {
                $item.addClass('day-disabled');
            }
            if ($element.hasClass('end-date')) {
                var startDate = datePickerContainer.closest('.end-date-container').prev().find('.start-date').val();
                if ($item.data('value') < startDate) {
                    $item.addClass('day-disabled');
                }
            }
        });

        datePickerDays.click(function (e) {
            if ($(this).hasClass('day-disabled')) {
                return;
            } else {
                customDatepickerWrapper.find('.selectedDate').removeClass('selectedDate');
                $(this).addClass('selectedDate');
                $element.val($(this).data('value'));
                $element.next().next().val($(this).data('value'));
                if(!page.includes('result') && !$element.hasClass('hotel')) {
                    $element.prev().find('.date-format').text(DateFormat($(this).data('value'), 'dDMY'));
                    $element.prev().removeClass('lh-45').find('p:first-child').css('font-size','13px');
                }
                (config.afterShow || (() => { }))();
                $element.change();
                customDatepickerWrapper.hide();
                if($element.closest('.start-date-container').data('triptype') == 'openReturn') {
                    $element.closest('.start-date-container').next().find('input.end-date').val(getNextMonth($(this).data('value')));
                    $element.closest('.start-date-container').next().find('input.return').val(getNextMonth($(this).data('value')));
                }
            }
        });
    }

    this.draw = function () {
        customDatepickerWrapper.find('.custom-datepicker-months').empty();
        var currentMonth;
        var targetMonth;
        for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
            var calcMonth = (me.month + monthIndex) % 12;
            var calcYear = me.year + Math.floor((me.month + monthIndex) / 12);
            targetMonth = $(datepickerTable.replace('currentmonth', me.months[calcMonth] + ' ' + (calcYear)));
            var daysInMonth = wrapDate.daysInMonth(calcYear, (calcMonth + 1));
            var weekday = wrapDate.getDay(calcYear, calcMonth);
            var weeks = [];
            wrapDate.colspanBeforeWeekday(weeks, weekday);

            for (var i = 1; i <= daysInMonth; i++) {
                var position = wrapDate.weekdayPosition(weekday, i);
                weeks[position] = weeks[position] || '';
                weeks[position] += '<td><div class="custom-datepicker-day" data-value="' + calcYear + '-' + ((calcMonth < 9) ? ('0' + (calcMonth + 1)) : (calcMonth + 1)) + '-' + (i < 10 ? ('0' + i) : i) + '">' + i + '</div></td>';
            }
            targetMonth.find('.days').append('<tr>' + weeks.join('</tr><tr>') + '</tr>');
            targetMonth.data('month', calcMonth);
            customDatepickerWrapper.find('.custom-datepicker-months').append(targetMonth);
            if ($element.val() != '') {
                currentMonth = wrapDate.getCurrentMonth($element.val());
                if (targetMonth.data('month') == currentMonth) {
                    targetMonth.addClass('active');
                }
            }
        }
        if ($element.val() == '') {
            if ($element.hasClass('end-date')) {
                let startDate = datePickerContainer.closest('.end-date-container').prev().find('.start-date').val();
                if (startDate != '') {
                    let startDateMonth = wrapDate.getCurrentMonth(startDate);
                    customDatepickerWrapper.find('.custom-datepicker-month').each((monthIndex, monthSection) => {
                        if ($(monthSection).data('month') == startDateMonth) {
                            $(monthSection).addClass('active');
                        }
                        $element.val(startDate);
                        $element.next().next().val(startDate);
                    });
                } else {
                    customDatepickerWrapper.find('.custom-datepicker-months>:first-child').addClass('active');
                }
            } else {
                customDatepickerWrapper.find('.custom-datepicker-months>:first-child').addClass('active');
            }
        }
        me.selectDate();
        me.controllerArrows();
    }

    this.goToPrevMonth = function () {
        if (nextBtn.prop("disabled") == true) {
            nextBtn.prop("disabled", false);
        }
        if (customDatepickerWrapper.find('.active').prev().length != 0) {
            customDatepickerWrapper.find('.active').removeClass('active').prev().addClass('active');
        }
        if (customDatepickerWrapper.find('.active').prev().length == 0) {
            prevBtn.prop("disabled", true);
        }

    }

    this.goToNextMonth = function () {
        if (prevBtn.prop("disabled") == true) {
            prevBtn.prop("disabled", false);
        }
        if (customDatepickerWrapper.find('.active').next().length != 0) {
            customDatepickerWrapper.find('.active').removeClass('active').next().addClass('active');
        }
        if (customDatepickerWrapper.find('.active').next().length == 0) {
            nextBtn.prop("disabled", true);
        }
    };

    this.controllerArrows = function () {
        if (customDatepickerWrapper.find('.active').next().length == 0) {
            nextBtn.prop("disabled", true);
        }

        if (customDatepickerWrapper.find('.active').prev().length == 0) {
            prevBtn.prop("disabled", true);
        }
    }

    nextBtn.click(me.goToNextMonth);
    prevBtn.click(me.goToPrevMonth);

    function getNextMonth (date) {
        var initialDate = new Date(date);
        var newDate = new Date(initialDate);
        newDate.setDate(initialDate.getDate() + 30);
        var newDateFormatted = newDate.toISOString().split('T')[0];
        return newDateFormatted;
    }
}