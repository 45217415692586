function DateFormat(date, format) {
    if (DateFormat.calendar == undefined) {
        throw new Error('Calendar Not config for date format');
    }
    var calendar = DateFormat.calendar;


    var gFormat = function (date, format) {
        switch (format) {
            case 'MDY':
                return dayjs(date).format('MMM DD, YYYY');
            case 'dMD':
                return dayjs(date).format('ddd, MMM DD');
            case 'dMDY':
                return dayjs(date).format("ddd, MMM DD,YYYY");
            case 'Y-M-D':
            case 'YMD':
                return dayjs(date).format("YYYY-MM-DD");
            case 'YMDHMS':
                return dayjs(date).format('YYYY-MM-DD H:mm:ss');
            case 'DMY':
                return dayjs(date).format('DD MMM, YYYY');
            case 'dddd':
                return dayjs(date).format('dddd');
            case 'MD':
                return dayjs(date).format('MMM DD');
            case 'DMMMY':
                return dayjs(date).format('DD MMM YYYY');
            case 'Y/M/D':
                return dayjs(date).format("YYYY/MM/DD");
            case 'dMDYHM':
                return dayjs(date).format("ddd,MMM,DD,YYYY H:mm");
            case 'MD/':
                return `<div>${dayjs(date).format('DD MMM')}</div>`;
            case 'dDMY':
                return dayjs(date).format("ddd, DD MMM YYYY");
        }
    };

    var jFormat = function (jalaliDate, gregorianDate, format) {
        switch (format) {
            case 'MDY':
                return `${jalaliDate.format('DD MMMM YYYY')} (${dayjs(gregorianDate).format('DD MMM')})`;
            case 'dMD':
                return `${jalaliDate.format('dddd DD MMMM')} (${dayjs(gregorianDate).format('DD MMM')})`;
            case 'dMDY':
                return `${jalaliDate.format("dddd, DD MMMM YYYY")} (${dayjs(gregorianDate).format('DD MMM')})`;
            case 'YMD':
                return `${jalaliDate.format("YYYY-MM-DD")} (${dayjs(gregorianDate).format("YYYY-MM-DD")})`;
            case 'YMDHMS':
                return `${jalaliDate.format('H:mm:ss DD-MM-YYYY')} (${dayjs(gregorianDate).format('DD-MM-YYYY')})`;
            case 'DMY':
                return `${jalaliDate.format('DD MMMM YYYY')} (${dayjs(gregorianDate).format('DD MMM')})`;
            case 'dddd':
                return `${jalaliDate.format('dddd')}`;
            case 'MD':
                return `${jalaliDate.format('DD MMMM')} (${dayjs(gregorianDate).format('DD MMM')})`;
            case 'DMMMY':
                return `${jalaliDate.format('DD MMMM YYYY')} (${dayjs(date).format('DD MMM YYYY')})`;
            case 'Y/M/D':
                return `${jalaliDate.format('YYYY/MM/DD')}`;
            case 'dMDYHM':
                return `${jalaliDate.format('ddd,MMM,DD,Y HH:mm')}`;
            case 'Y-M-D':
                return `${jalaliDate.format("YYYY-MM-DD")}`;
            case 'MD/':
                return `<div class="_mb-5">${jalaliDate.format('DD MMMM')}</div> <div>(${dayjs(gregorianDate).format('DD MMM')})</div>`
        }
    };
    if (calendar == 'jalali') {
        var jalaliDate = (new persianDate(new Date(date)));
        var gregorianDate = new Date(date);
        return jFormat(jalaliDate, gregorianDate, format);

    } else if (calendar == 'gregorian') {
        var date = new Date(date);
        return gFormat(date, format)
    }
}